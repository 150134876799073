@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
@font-face {
    font-family: "Nunito";
    src: url("../assests/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
    font-weight: 1;
    font-style: normal;
}

html {
    scroll-behavior: smooth;
}

*,
*::after,
*::before {
    box-sizing: border-box;
}
body::-webkit-scrollbar { width: 12px; /* Ширина скроллбара */ } body::-webkit-scrollbar-track { background: #f1f1f1; /* Цвет фона трека скроллбара */ } body::-webkit-scrollbar-thumb { background: #888; /* Цвет ползунка скроллбара */ border-radius: 6px; /* Скругленные углы */ } body::-webkit-scrollbar-thumb:hover { background: #555; /* Цвет ползунка скроллбара при наведении */ } body::-webkit-scrollbar-thumb:active { background: #333; /* Цвет ползунка скроллбара при нажатии */
                                                                                                                                                                                                                                                                                                                                                                                              }
body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: "Nunito", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    @include font-SegoeUI();
}

.container {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
    padding-block: 0;
    @include responsive-sizing("padding-inline", 8, 32, 360,1440);

}
